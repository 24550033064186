import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import CTA from '../components/CTA';
import BlogSection from '../components/BlogSection';

import { Columns, Column, Container, Footer, Level, Left, Right, Menu, MenuItem, Section } from '../components/bulma';

export const PricingPageTemplate = ({
	image,
	title,
	heading,
	description,
	intro,
	main,
	testimonials,
	fullImage,
	pricing
}) => (
	<div className="content">
		<br />
		<br />
		<Hero title={heading} subtitle={description} image="image" />
		<Pricing data={pricing.plans} />

		<CTA />
	</div>
);

PricingPageTemplate.propTypes = {
	image: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
	title: PropTypes.string,
	heading: PropTypes.string,
	description: PropTypes.string,
	intro: PropTypes.shape({
		blurbs: PropTypes.array
	}),
	main: PropTypes.shape({
		heading: PropTypes.string,
		description: PropTypes.string,
		image1: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
		image2: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
		image3: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ])
	}),
	testimonials: PropTypes.array,
	fullImage: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
	pricing: PropTypes.shape({
		heading: PropTypes.string,
		description: PropTypes.string,
		plans: PropTypes.array
	})
};

const PricingPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout>
			<PricingPageTemplate
				image={frontmatter.image}
				title={frontmatter.title}
				heading={frontmatter.heading}
				description={frontmatter.description}
				intro={frontmatter.intro}
				main={frontmatter.main}
				testimonials={frontmatter.testimonials}
				fullImage={frontmatter.full_image}
				pricing={frontmatter.pricing}
			/>
		</Layout>
	);
};

PricingPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object
		})
	})
};

const Pricing = ({ data }) => (
	<Section>
		<Columns>
			{data.map((price) => (
				<Column key={price.plan}>
					<div className="box notification">
						<div className="content ">
							<h3 className="title is-size-3">{price.plan}</h3>
							{/* <h4 className="subtitle is-size-6">{price.description}</h4> */}
							<h4 className="subtitle is-size-1">{price.price}</h4>
							<a href={price.cta.url} className="button is-black is-fullwidth is-medium">
								<strong>{price.cta.label}</strong>
							</a>
							<ul>
								{price.items.map((item) => (
									<li key={item} className="is-size-5">
										{item}
									</li>
								))}
							</ul>
						</div>
					</div>
				</Column>
			))}
		</Columns>
	</Section>
);

export default PricingPage;

export const pricingPageQuery = graphql`
	query PricingPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
				heading
				description
				intro {
					blurbs {
						image {
							childImageSharp {
								fluid(maxWidth: 240, quality: 64) {
									...GatsbyImageSharpFluid
								}
							}
						}
						text
					}
					heading
					description
				}
				main {
					heading
					description
					image1 {
						alt
						image {
							childImageSharp {
								fluid(maxWidth: 526, quality: 92) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					image2 {
						alt
						image {
							childImageSharp {
								fluid(maxWidth: 526, quality: 92) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					image3 {
						alt
						image {
							childImageSharp {
								fluid(maxWidth: 1075, quality: 72) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
				testimonials {
					author
					quote
				}
				full_image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
				pricing {
					heading
					description
					plans {
						description
						items
						plan
						price
						cta {
							label
							url
						}
					}
				}
			}
		}
	}
`;
